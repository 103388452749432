import classNames from 'classnames'
import { CircleX, X } from 'lucide-react'
import React from 'react'
import InputRange from '../../InputRange/RangeSlider'
import styles from './ExpandFilter.module.scss'

export default function ExpandFilter({
	activeTab,
	rooms,
	rangeValues,
	handleRangeChange,
	handleResetFilter,
	resetFlag,
	showFilter,
	toggleRoomSelection,
}) {
	return (
		<div
			className={classNames(
				styles.expandableBlock,
				activeTab !== 'filters' || !showFilter ? styles.hidden : ''
			)}
		>
			<div className={styles.rooms}>
				{/* <HousePlus /> */}
				<p>Количество комнат</p>
				<div className={styles.numberButtons}>
					{[0, 1, 2, 3, 4, 5].map(num => (
						<button
							key={num}
							onClick={() => toggleRoomSelection(num)}
							className={rooms.includes(num) ? styles.active : ''}
						>
							{num === 0 ? <X /> : num}
						</button>
					))}
				</div>
			</div>
			<div className={styles.sliders}>
				<div className={classNames(styles.wrapRange, styles.surface)}>
					<div className={classNames(styles.labelSurface, styles.label)}>
						<p>
							Площадь, м<sup>2</sup>
						</p>
					</div>

					<InputRange
						min={60}
						max={240}
						step={1}
						values={rangeValues.surfaceRange}
						resetFlag={resetFlag}
						onChange={values => handleRangeChange('surfaceRange', values)}
					/>
				</div>
				<div className={classNames(styles.wrapRange, styles.floor)}>
					<div className={classNames(styles.labelFloor, styles.label)}>
						<p>Этаж</p>
					</div>

					<InputRange
						min={1}
						max={10}
						step={1}
						values={rangeValues.floorRange}
						resetFlag={resetFlag}
						onChange={values => handleRangeChange('floorRange', values)}
					/>
				</div>
				<div className={classNames(styles.wrapRange, styles.cost)}>
					<div className={classNames(styles.labelCost, styles.label)}>
						<p>Цена, млн. ₽</p>
					</div>
					<InputRange
						values={rangeValues.costRange}
						resetFlag={resetFlag}
						onChange={values => handleRangeChange('costRange', values)}
					/>
				</div>
			</div>
			<div className={styles.reset}>
				<div className={styles.wrapBtnReset}>
					<button onClick={() => handleResetFilter()}>
						<CircleX className='mr-1' />
						Cбросить
					</button>
				</div>
			</div>
			{/* <div className={styles.available}>
					<p>Доступность</p>
					<div className={styles.availableButtons}>
						<button onClick={() => setAvailable(null)}>
							<CircleEllipsis />
						</button>
						<button onClick={() => setAvailable(true)}>
							<CircleCheck />
						</button>
						<button onClick={() => setAvailable(false)}>
							<CircleX />
						</button>
					</div>
				</div> */}
		</div>
	)
}
