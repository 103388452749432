import classNames from 'classnames'
import {
	ChevronUp,
	Earth,
	Expand,
	Heart,
	Images,
	MapPinHouse,
	Shrink,
	SlidersHorizontal,
} from 'lucide-react'
import React, { useState } from 'react'
import styles from './DemoFilter.module.scss'
import ExpandFilter from './ExpandFilter/ExpandFilter'
import ExpandGallery from './ExpandGallery/ExpandGallery'

export default function DemoFilter({
	activeTab,
	setActiveTab,
	toggleFilter,
	rooms,
	setRooms,
	rangeValues,
	handleRangeChange,
	available,
	setAvailable,
	isFullScreen,
	handleFullScreenChange,
	handleResetFilter,
	resetFlag,
	openModal,
}) {
	const [showFilter, setShowFilter] = useState(true)

	const handleTabClick = tabName => {
		setActiveTab(tabName)
	}

	const toggleRoomSelection = num => {
		if (num === 0) {
			// Сбрасываем все выбранные комнаты
			setRooms([])
		} else {
			setRooms(
				prevRooms =>
					prevRooms.includes(num)
						? prevRooms.filter(room => room !== num) // Убираем комнату, если она уже выбрана
						: [...prevRooms, num] // Добавляем комнату, если она не выбрана
			)
		}
	}

	const stopPropagation = e => e.stopPropagation()

	return (
		<div>
			<div className={styles.filter}>
				<div
					className={classNames(styles.filtersTab, {
						[styles.active]: activeTab === 'overview',
					})}
					onClick={() => handleTabClick('overview')}
				>
					<Earth />
					<p>Обзор</p>
				</div>
				<div
					className={classNames(styles.filtersTab, {
						[styles.active]: activeTab === 'filters',
					})}
					onClick={() => handleTabClick('filters')}
				>
					<SlidersHorizontal />
					<p>Фильтры</p>
				</div>

				<div
					className={classNames(styles.filtersTab, {
						[styles.active]: activeTab === 'gallery',
					})}
					onClick={() => handleTabClick('gallery')}
				>
					<Images />
					<p>Галерея</p>
				</div>
				<div
					className={classNames(styles.filtersTab, styles.disabled, {
						[styles.active]: activeTab === 'environment',
					})}
					onClick={() => handleTabClick('environment')}
				>
					<MapPinHouse />
					<p>Окружение</p>
				</div>
				<div
					className={classNames(styles.filtersTab, styles.disabled, {
						[styles.active]: activeTab === 'favorites',
					})}
					onClick={() => handleTabClick('favorites')}
				>
					<Heart />
					<p>Избранное</p>
				</div>
				<div className={styles.fullScreenTab}>
					<button onClick={handleFullScreenChange}>
						{isFullScreen ? <Shrink /> : <Expand />}
					</button>
				</div>
			</div>
			<div
				className={classNames(
					activeTab === 'filters' ? styles.closeExpand : styles.hidden,
					showFilter ? styles.active : ''
				)}
			>
				<ChevronUp
					className={showFilter ? styles.close : ''}
					onClick={() => setShowFilter(prev => !prev)}
				/>
			</div>
			<ExpandFilter
				activeTab={activeTab}
				showFilter={showFilter}
				toggleRoomSelection={toggleRoomSelection}
				rooms={rooms}
				rangeValues={rangeValues}
				handleRangeChange={handleRangeChange}
				available={available}
				setAvailable={setAvailable}
				handleResetFilter={handleResetFilter}
				resetFlag={resetFlag}
			/>
			{activeTab === 'gallery' && <ExpandGallery openModal={openModal} />}
		</div>
	)
}
