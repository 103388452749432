import classNames from 'classnames'
import { X } from 'lucide-react'
import React, { useState } from 'react'
import styled from 'styled-components'
import Video from '../../../blocks/Video/Video'

const images = {
	'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий':
		[
			'/images/landscapeDesign/4.webp',
			'/images/landscapeDesign/5.webp',
			'/images/landscapeDesign/6.webp',
			'/images/landscapeDesign/7.webp',
			'/images/landscapeDesign/8.webp',
			'/images/landscapeDesign/9.webp',
			'/images/landscapeDesign/10.webp',
		],
	'Дизайн интерьера + спецификация': [
		'/images/designInterier/1.webp',
		'/images/designInterier/2.webp',
		'/images/designInterier/3.webp',
		'/images/designInterier/4.webp',
		'/images/designInterier/5.webp',
		'/images/designInterier/6.webp',
		'/images/designInterier/7.webp',
	],
}

const classesGrid = {
	'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий':
		[
			'col-span-1 row-start-1 col-start-1',
			'col-span-2 row-start-1 col-start-2',
			'col-span-1 row-start-1 col-start-4',
			'col-span-1 row-start-2 col-start-1',
			'col-span-1 row-start-2 col-start-2',
			'col-span-1 row-start-2 col-start-3',
			'col-span-1 row-start-2 col-start-4',
		],
	'Дизайн интерьера + спецификация': [
		'col-span-1 row-start-1 col-start-1',
		'col-span-1 row-start-2 col-start-1',
		'col-span-1 row-start-1 col-start-2',
		'col-span-1 row-start-2 col-start-2',
		'col-span-1 row-start-1 col-start-3',
		'col-span-1 row-start-2 col-start-3',
		'row-span-2 row-start-1 col-start-4',
	],
}

const ImageContainer = styled.div`
	border-radius: 8px;
	cursor: pointer;

	&.active {
		transform: scale(1.2);
		z-index: 2;
	}

	@media (hover: hover) {
		&.blur {
			filter: blur(3px);
		}
	}
	transition: transform 0.1s ease-in-out;
`

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export default function ExpandGallery({ activeTab, openModal }) {
	const [selectedFilter, setSelectedFilter] = useState('GalleryMain')
	const [hoveredIndex, setHoveredIndex] = useState(null)

	const tabs = [
		{
			image: '/images/landscapeDesign/2.webp',
			text: 'Интерактивная визуализация архитектуры, ландшафтного дизайна и прилегающих территорий',
		},
		{
			image: '/images/portfolio.webp',
			text: 'Фотореалистичные маркетинговые изображения и видео',
		},
		{
			image: '/images/designInterier/1.webp',
			text: 'Дизайн интерьера + спецификация',
		},
	]

	const handleModal = ({ src }) => {
		openModal('Пример', <StyledImage src={src} alt='Architecture 1' />, true)
	}

	return (
		<div
			className={`absolute inset-0 flex flex-col md:flex-row justify-center items-center bg-no-repeat bg-cover bg-center`}
		>
			<button
				className='absolute top-4 left-4 bg-black bg-opacity-50 rounded p-4 hover:bg-opacity-100 transition-all'
				onClick={() => setSelectedFilter('GalleryMain')}
			>
				<X />
			</button>

			{selectedFilter === 'GalleryMain' &&
				tabs.map((tab, i) => (
					<div
						key={i}
						className={`flex-[1_1_100%] w-full h-full flex justify-center items-center cursor-pointer bg-no-repeat bg-cover bg-center`}
						style={{ backgroundImage: `url(${tab.image})` }}
						onClick={() => setSelectedFilter(tab.text)}
					>
						<span className='bg-black bg-opacity-50 rounded px-8 py-4'>
							<p className='text-xl'>{tab.text}</p>
						</span>
					</div>
				))}
			{selectedFilter === tabs[0].text && (
				<div
					className={`flex-[1_1_100%] w-full h-full flex justify-center items-center cursor-pointer bg-no-repeat bg-cover bg-center`}
					style={{ backgroundImage: `url(/Demo/loadImg.webp)` }}
				>
					<div className='w-auto max-w-[90%] md:max-w-[80%] h-auto max-h-[80%] rounded-lg overflow-hidden object-contain aspect-video '>
						<div className='h-full w-full grid grid-cols-4 grid-rows-2'>
							{images[selectedFilter].map((src, index) => (
								<ImageContainer
									key={src}
									className={classNames(classesGrid[selectedFilter][index], {
										active: hoveredIndex === index,
										blur: hoveredIndex !== null && hoveredIndex !== index,
									})}
									onMouseEnter={() => setHoveredIndex(index)}
									onMouseLeave={() => setHoveredIndex(null)}
									onClick={() => handleModal({ src })}
								>
									<StyledImage src={src} alt='Architecture 1' />
								</ImageContainer>
							))}
						</div>
					</div>
				</div>
			)}
			{selectedFilter === tabs[1].text && (
				<div
					className={`flex-[1_1_100%] w-full h-full flex justify-center items-center cursor-pointer bg-no-repeat bg-cover bg-center`}
					style={{ backgroundImage: `url(/Demo/loadImg.webp)` }}
				>
					<Video
						styleVideo={
							'w-auto h-auto max-w-[90%] max-h-[90%] md:max-w-[80%] flex p-5 border-bottom-2 border-[#5d5a5a] overflow-hidden object-contain aspect-video'
						}
					/>
				</div>
			)}
			{selectedFilter === tabs[2].text && (
				<div
					className={`flex-[1_1_100%] w-full h-full flex justify-center items-center cursor-pointer bg-no-repeat bg-cover bg-center`}
					style={{ backgroundImage: `url(/Demo/loadImg.webp)` }}
				>
					<div className='w-auto max-w-[90%] md:max-w-[80%] h-auto max-h-[80%] rounded-lg overflow-hidden object-contain aspect-video '>
						<div className='h-full w-full grid grid-cols-4 grid-rows-2'>
							{images[selectedFilter].map((src, index) => (
								<ImageContainer
									key={src}
									className={classNames(classesGrid[selectedFilter][index], {
										active: hoveredIndex === index,
										blur: hoveredIndex !== null && hoveredIndex !== index,
									})}
									onMouseEnter={() => setHoveredIndex(index)}
									onMouseLeave={() => setHoveredIndex(null)}
									onClick={() => handleModal({ src })}
								>
									<StyledImage src={src} alt='Architecture 1' />
								</ImageContainer>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
